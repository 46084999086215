import { darken } from 'polished';

import styled from 'styled-components';

interface ContainerProps {
  visible: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 350px;
  position: sticky;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background: #232d41;
  overflow-y: auto;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 15px;
  box-shadow: 0px -1px 5px 0px #999;
  p {
    margin: 0px;
    margin-right: 15px;
    line-height: 20px;
    @media screen and (max-width: 768px) {
      margin-right: 10px;
    }
  }
  a {
    color: #fff;
    transition-duration: 0.3s;
    :hover {
      color: ${darken(0.1, '#fff')};
    }
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    height: 40px;
    border: none;
    border-radius: 10px;
    transition-duration: 0.3s;
    font-weight: bold;
    font-size: 15px;
    padding: 5px 10px;
    margin-top: 0px;
    background: #fff;
    color: #232d41;

    :hover {
      background-color: ${darken(0.3, '#fff')};
    }
  }
  @media screen and (min-width: 769px) {
    .box-info-lgpd {
      p:first-child {
        margin-bottom: 15px;
      }
      button {
        width: 70px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding: 35px 15px;
    display: ${(props) => (props.visible ? 'block' : 'none')};
    .box-info-lgpd {
      p {
        margin-right: 10px;
        margin-bottom: 20px;
        span {
          margin-top: 20px;
          display: block;
        }
      }
    }
    button {
      width: 100%;
    }
  }
`;
