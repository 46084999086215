/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Link } from 'react-router-dom';

import { Container } from './styles';

const GDPRPanel: React.FC = () => {
  function getCookie(name: string) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop()?.split(';').shift();
    }
    return false;
  }

  const cookiePermissioned = getCookie('permissioned');
  const cookieToSet = cookiePermissioned === 'allow';

  function gaOptout(allow: boolean) {
    if (allow === false) {
      const gaProperty = 'G-BC4K1WFZY3';
      const disableStr = `ga-disable-${gaProperty}`;
      document.cookie = `${disableStr}=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/`;
    }
  }

  const date = new Date();
  date.setDate(date.getDate() + 90);

  return (
    <Container visible={!cookieToSet}>
      <div className="box-info-lgpd">
        <p className="font-weight-bold h5">Aviso de Cookies</p>
        <p>
          A Fiari clean utiliza cookies em seu dispositivo para melhorar a
          navegação no site, analisar o uso do site e auxiliar em nossos
          esforços de marketing.
          <span>
            &nbsp;Para mais informações acesse a{' '}
            <Link to={`${process.env.PUBLIC_URL}/politicas-de-privacidade`}>
              Política de Privacidade.
            </Link>
          </span>
        </p>
      </div>
      <button
        type="button"
        className="bg-button-grande button-azul small font-weight-bold mx-2 pb-2 inverse mx-auto mb-2"
        onClick={() => {
          gaOptout(!cookieToSet);
          document.cookie = `permissioned=allow; expires=${date.toUTCString()}`;
          document.location.reload();
        }}
      >
        <span className="d-none d-md-block">Aceitar</span>
        <small className="d-block d-md-none font-weight-bold">Aceitar</small>
      </button>
    </Container>
  );
};

export default GDPRPanel;
