import styled from 'styled-components';

export const Banner = styled.div`
  div {
    h1 {
      font-weight: 600;
      color: #2a2a2a;
      margin-bottom: 30px;
    }

    h2 {
      font-weight: 400;
      color: #c5ccd6;
    }
  }
`;

export const NossaEmpresa = styled.div`
  h2 {
    font-weight: 600;
    color: #707070;
    margin-bottom: 30px;
  }

  p {
    color: #c5ccd6;
  }
`;

export const SobreMim = styled.div`
  padding: 3rem;
  background-color: #8e9da9b0;

  h2 {
    color: #ffffff;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }

  p {
    margin-top: 1rem;
    color: #ffffff;
  }

  @media screen and (max-width: 767px) {
    padding: 3rem 0;
  }
`;

export const SobreNossosProdutos = styled.div`
  h2 {
    font-weight: 600;
    color: #707070;
    margin-bottom: 30px;
  }

  p {
    color: #c5ccd6;
  }
`;

export const NosVamosAteVoce = styled.div`
  h2 {
    font-weight: 600;
    color: #707070;
    margin-bottom: 30px;
  }

  p {
    color: #c5ccd6;
  }
`;
