import styled from 'styled-components';

interface IActive {
  active: boolean;
}

export const Banner = styled.div`
  div {
    h1 {
      font-weight: 600;
      color: #2a2a2a;
      margin-bottom: 30px;
    }

    h3 {
      font-weight: 400;
      color: #c5ccd6;
    }
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;

  button {
    border: none;
    background: none;
    color: #707070;
    font-weight: 600;
    transition-duration: 0.3s;

    :hover {
      color: #78aad5;
    }

    + button {
      ::before {
        margin: 0 10px;
        content: '|';
        color: #707070;
        font-weight: 400;
      }
    }
  }

  button.active {
    color: #78aad5;
  }

  @media screen and (max-width: 767px) {
    display: block;
    text-align: center;

    button {
      width: 100%;
      margin: 5px 0;

      + button {
        ::before {
          content: '';
          margin: 0;
        }
      }
    }
  }
`;

export const EstofadosEColchoes = styled.div<IActive>`
  transition-duration: 0.5s;
  display: ${(props) => (props.active ? 'block' : 'none')};

  button.box {
    box-shadow: 10px 9px 6px #8eb0ab26;
    border-radius: 13px;
    cursor: pointer;
    background: #ffffff;
    border: none;

    div.p-relative {
      position: relative;

      div.shadowHover {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: #ffffff99;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition-duration: 0.3s;
      }
    }

    :hover {
      div.p-relative {
        div.shadowHover {
          opacity: 1;
        }
      }
    }
  }

  h2 {
    font-weight: 600;
    color: #707070;
    margin-bottom: 30px;
  }

  p {
    color: #707070;
  }

  @media screen and (max-width: 767px) {
    div.p-relative {
      div.shadowHover {
        opacity: 1 !important;
      }
    }
  }
`;

export const Automoveis = styled.div<IActive>`
  transition-duration: 0.5s;
  display: ${(props) => (props.active ? 'block' : 'none')};

  button.box {
    box-shadow: 10px 9px 6px #8eb0ab26;
    border-radius: 13px;
    cursor: pointer;
    background: #ffffff;
    border: none;

    div.p-relative {
      position: relative;

      div.shadowHover {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: #ffffff99;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition-duration: 0.3s;
      }
    }

    :hover {
      div.p-relative {
        div.shadowHover {
          opacity: 1;
        }
      }
    }
  }

  h2 {
    font-weight: 600;
    color: #707070;
    margin-bottom: 30px;
  }

  p {
    color: #707070;
  }

  @media screen and (max-width: 767px) {
    div.p-relative {
      div.shadowHover {
        opacity: 1 !important;
      }
    }
  }
`;

export const Tratamentos = styled.div<IActive>`
  transition-duration: 0.5s;
  display: ${(props) => (props.active ? 'block' : 'none')};

  button.box {
    box-shadow: 10px 9px 6px #8eb0ab26;
    border-radius: 13px;
    cursor: pointer;
    background: #ffffff;
    border: none;

    div.p-relative {
      position: relative;

      div.shadowHover {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: #ffffff99;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition-duration: 0.3s;
      }
    }

    :hover {
      div.p-relative {
        div.shadowHover {
          opacity: 1;
        }
      }
    }
  }

  h2 {
    font-weight: 600;
    color: #707070;
    margin-bottom: 30px;
  }

  p {
    color: #707070;
  }

  @media screen and (max-width: 767px) {
    div.p-relative {
      div.shadowHover {
        opacity: 1 !important;
      }
    }
  }
`;

export const Outros = styled.div<IActive>`
  transition-duration: 0.5s;
  display: ${(props) => (props.active ? 'block' : 'none')};

  button.box {
    box-shadow: 10px 9px 6px #8eb0ab26;
    border-radius: 13px;
    cursor: pointer;
    background: #ffffff;
    border: none;

    div.p-relative {
      position: relative;

      div.shadowHover {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: #ffffff99;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition-duration: 0.3s;
      }
    }

    :hover {
      div.p-relative {
        div.shadowHover {
          opacity: 1;
        }
      }
    }
  }

  h2 {
    font-weight: 600;
    color: #707070;
    margin-bottom: 30px;
  }

  p {
    color: #707070;
  }

  @media screen and (max-width: 767px) {
    div.p-relative {
      div.shadowHover {
        opacity: 1 !important;
      }
    }
  }
`;
