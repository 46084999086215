import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';

import Header from '~/components/Header';
import Routes from '~/routes';
import Footer from '~/components/Footer';
import GlobalStyles from './styles/global';

const App: React.FC = () => {
  return (
    <HttpsRedirect disabled={process.env.NODE_ENV === 'development'}>
      <BrowserRouter>
        <Header />
        <Routes />
        <Footer />
        <GlobalStyles />
      </BrowserRouter>
    </HttpsRedirect>
  );
};

export default App;
