import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Home from '~/pages/Home';
import SobreNos from '~/pages/SobreNos';
import Servicos from '~/pages/Servicos';
import Contato from '~/pages/Contato';
import PoliticaPrivacidade from '~/pages/PoliticaPrivacidade';

const routes: React.FC = () => {
  return (
    <Switch>
      <Route path={`${process.env.PUBLIC_URL}/`} exact component={Home} />
      <Route
        path={`${process.env.PUBLIC_URL}/sobre-nos`}
        component={SobreNos}
      />
      <Route path={`${process.env.PUBLIC_URL}/servicos`} component={Servicos} />
      <Route path={`${process.env.PUBLIC_URL}/contato`} component={Contato} />
      <Route
        path={`${process.env.PUBLIC_URL}/politicas-de-privacidade`}
        component={PoliticaPrivacidade}
      />

      <Route path={`${process.env.PUBLIC_URL}/index.html`}>
        <Redirect to={`${process.env.PUBLIC_URL}/`} />
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/sobre-nos.html`}>
        <Redirect to={`${process.env.PUBLIC_URL}/sobre-nos`} />
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/servicos.html`}>
        <Redirect to={`${process.env.PUBLIC_URL}/servicos`} />
      </Route>
      <Route path={`${process.env.PUBLIC_URL}/contato.html`}>
        <Redirect to={`${process.env.PUBLIC_URL}/contato`} />
      </Route>
    </Switch>
  );
};

export default routes;
