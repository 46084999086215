import React, { useEffect } from 'react';
import PoliticaPrivacidadeComponent from '~/components/PoliticaPrivacidade';

const PoliticaPrivacidade: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PoliticaPrivacidadeComponent
      nome="Fiari Clena"
      email="contato@fiariclean.com.br"
      site="https://fiariclean.com.br"
      cnpj="27.786.617/0001-30"
      endereco="Rua Cesário Verde, 95 Jardim Tupã - Barueri, SP. CEP 06435-050"
      razaoSocial="Fiari CLean"
      plataforma="Google"
      titleColor="#232d41"
      contentTextColor="#232d41"
      fontRootSize="50%"
    />
  );
};

export default PoliticaPrivacidade;
