import axios from 'axios';

const api = axios.create({
  // baseURL: 'http://localhost/fiari-backend',
  baseURL: 'https://www.fiariclean.com.br/disparar',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

export default api;
