/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useCallback, useEffect } from 'react';
import { FaWhatsapp, FaEnvelope } from 'react-icons/fa';
import { Modal } from 'react-bootstrap';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { Helmet } from 'react-helmet';

import api from '~/services/api';

import { Banner, CardContato } from './styles';

const Contato: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = useCallback(() => setShow(false), []);
  const handleShow = useCallback(() => setShow(true), []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = useCallback(async ({ nome, email, mensagem }) => {
    setLoading(true);
    try {
      const data = new URLSearchParams();
      data.append('nome', nome);
      data.append('email', email);
      data.append('mensagem', mensagem);

      const response = await api.post('/', data);
      if (response.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Tudo certo!',
          text: 'Em breve entraremos em contato.',
          confirmButtonText: `Ok`,
        }).then(() => {
          setShow(false);
          setLoading(false);
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Ocorreu um erro, tente novamente.',
          confirmButtonText: `Ok`,
        }).then(() => {
          setShow(false);
          setLoading(false);
        });
      }
    } catch {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Ocorreu um erro, tente novamente.',
        confirmButtonText: `Ok`,
      }).then(() => {
        setShow(false);
        setLoading(false);
      });
    }
  }, []);

  const schema = Yup.object().shape({
    nome: Yup.string().required('O nome é obrigatório'),
    email: Yup.string()
      .email('E-mail inválido')
      .required('O e-mail é obrigatório'),
    mensagem: Yup.string().required('A mensagem é obrigatório'),
  });

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://www.fiariclean.com.br/contato" />
        <title>
          Precisando de ajuda ou uma limpeza entre em contato com a Fiari.
        </title>
        <meta
          name="description"
          content="Esta precisando algum tipo de ajuda para limpeza de estofados ou hidratação de couro? Entre em contato com a Fiari Clean e faça já um orçamento."
        />
      </Helmet>
      <Banner className="py-5">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1 className="display-4">Contate-nos</h1>
              <h2 className="h5">
                Contate-nos, você poderá solicitar orçamentos, agendamentos.{' '}
                <br />
                Isso tudo com eficiência e rapidez.
              </h2>
            </div>
          </div>
        </div>
      </Banner>
      <CardContato className="pb-5">
        <div className="container card">
          <div className="row">
            <div className="col-md-5 bg-contato-card d-flex align-items-end">
              <div className="border-md-left border-white border-logo-card pl-3 py-2 m-3 text-center text-md-left w-sm-100">
                <h3 className="mb-0">
                  Fiari
                  <br />
                  Clean
                </h3>
                <p className="mb-0 text-white">tudo limpo</p>
              </div>
            </div>
            <div className="col-md-7 p-5 text-center text-md-left">
              <h3 className="h1">Entre em contato</h3>
              <p className="mb-5 pb-5">
                A empresa possui infraestrutura para atender a demanda de
                comércios, residências e empresas.
              </p>

              <div className="d-flex flex-column flex-lg-row justify-content-center mt-5 pt-5">
                <button
                  type="button"
                  className="btn btn-transparent rounded-pill d-flex align-items-center mx-3 my-2 my-lg-0"
                  onClick={handleShow}
                >
                  <FaEnvelope size={20} color="#fff" className="mr-1" />
                  Enviar E-mail
                </button>
                <a
                  href="https://api.whatsapp.com/send?phone=5511975685553"
                  target="blank"
                  className="btn btn-blue rounded-pill d-flex align-items-center mx-3 my-2 my-lg-0"
                >
                  <FaWhatsapp size={20} color="#fff" className="mr-1" />
                  Enviar Whatsapp
                </a>
              </div>
            </div>
          </div>
        </div>
      </CardContato>

      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleSubmit} schema={schema}>
          <Modal.Header closeButton>
            <Modal.Title>Enviar E-mail</Modal.Title>
          </Modal.Header>
          <Modal.Body className="formModal">
            <Input
              name="nome"
              placeholder="Nome"
              className="form-control mb-2"
            />
            <Input
              type="email"
              name="email"
              placeholder="E-mail"
              className="form-control mb-2"
            />
            <Input
              name="mensagem"
              placeholder="Menssagem"
              multiline
              className="form-control mb-2"
            />
          </Modal.Body>

          <Modal.Footer>
            <button
              type="button"
              className="btn btn-light-blue py-2 px-3"
              onClick={handleClose}
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="btn btn-dark-blue py-2 px-3"
              disabled={loading}
            >
              {loading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                'Enviar'
              )}
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default Contato;
