import { createGlobalStyle } from 'styled-components';
import { shade } from 'polished';

import 'bootstrap/dist/css/bootstrap.min.css';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;600&display=swap');

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #232d41;
  }

  *{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body{
    -webkit-font-smoothing: antialiased;
  }

  body, input, button {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6, strong{
    font-weight: 500
  }

  button {
    cursor: pointer;
  }

  button:focus{
    outline: none;
    box-shadow: none !important;
  }

  .btn-transparent {
    border: 1px solid #fff;
    background: transparent;
    color: #fff;
    :hover {
      border-color: ${shade(0.3, '#fff')};
      color: ${shade(0.3, '#fff')};
    }
  }

  .btn-blue {
    background: rgba(255, 255, 255, 0.15);
    color: #fff;
    :hover {
      background: ${shade(0.1, '#ffffff26')};
      color: ${shade(0.3, '#fff')};
    }
  }

  .btn-light-blue {
    background-color: #83bad3;
    color: #1d2433;
    font-weight: 600;
    padding: 10px;
    border-radius: 15px;

    :hover{
      background-color: ${shade(0.1, '#83bad3')};
    }
  }

  .btn-dark-blue {
    background-color: #557989;
    color: #1d2433;
    font-weight: 600;
    padding: 10px;
    border-radius: 15px;

    :hover{
      background-color: ${shade(0.1, '#557989')};
    }
  }

  .font-weight-thin {
    font-weight: 100;
  }

  .formModal span{
    margin-top: -0.5rem;
    display: block;
    margin-bottom: 0.5rem;
    font-size: 13px;
    color: #ec2235;
  }

  @media screen and (max-width: 991px){
    .display-4{
      font-size: 3rem;
    }
  }

  @media screen and (max-width: 767px){
    .display-4{
      font-size: 2.5rem;
    }

    .w-sm-100{
      width: 100% !important;
    }
  }
`;
