import React, { useEffect, useRef, useState } from 'react';
import { isWebpSupported } from 'react-image-webp/dist/utils';
import { Link, useLocation } from 'react-router-dom';

import { Offset, HeaderComponent, Content, MenuButton, Menu } from './styles';

const logo = isWebpSupported()
  ? require('~/assets/logotipos/logo.webp')
  : require('~/assets/logotipos/logo.png');

const Header: React.FC = () => {
  const offsetRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLElement>(null);
  const location = useLocation();
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    if (headerRef.current && offsetRef.current) {
      const { offsetHeight } = headerRef.current;
      offsetRef.current.style.marginBottom = `${offsetHeight}px`;
    }
  }, []);

  return (
    <>
      <Offset ref={offsetRef} />
      <HeaderComponent ref={headerRef} className="fixed-top">
        <Content className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-6 col-md-2">
              <Link
                onClick={() => setOpened(!opened)}
                to={`${process.env.PUBLIC_URL}/`}
              >
                <img src={logo} alt="Logo" />
              </Link>
            </div>
            <div className="col-6 col-md-10">
              <MenuButton
                className="ml-auto"
                onClick={() => setOpened(!opened)}
                opened={opened}
              >
                <span />
                <span />
                <span />
              </MenuButton>
              <Menu opened={opened}>
                <li>
                  <Link
                    onClick={() => setOpened(!opened)}
                    to={`${process.env.PUBLIC_URL}/`}
                    className={`${
                      location.pathname === `${process.env.PUBLIC_URL}/`
                        ? 'active'
                        : ''
                    }`}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => setOpened(!opened)}
                    to={`${process.env.PUBLIC_URL}/sobre-nos`}
                    className={`${
                      location.pathname ===
                      `${process.env.PUBLIC_URL}/sobre-nos`
                        ? 'active'
                        : ''
                    }`}
                  >
                    Sobre Nós
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => setOpened(!opened)}
                    to={`${process.env.PUBLIC_URL}/servicos`}
                    className={`${
                      location.pathname === `${process.env.PUBLIC_URL}/servicos`
                        ? 'active'
                        : ''
                    }`}
                  >
                    Serviços
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => setOpened(!opened)}
                    to={`${process.env.PUBLIC_URL}/contato`}
                    className={`${
                      location.pathname === `${process.env.PUBLIC_URL}/contato`
                        ? 'active'
                        : ''
                    }`}
                  >
                    Contato
                  </Link>
                </li>
              </Menu>
            </div>
          </div>
        </Content>
      </HeaderComponent>
    </>
  );
};

export default Header;
