import styled from 'styled-components';
import { isWebpSupported } from 'react-image-webp/dist/utils';

const contato = isWebpSupported()
  ? require('~/assets/contato/contato.webp')
  : require('~/assets/contato/contato.png');

export const Banner = styled.div`
  div {
    h1 {
      font-weight: 600;
      color: #2a2a2a;
      margin-bottom: 30px;
    }

    h2 {
      font-weight: 400;
      color: #c5ccd6;
    }
  }
`;

export const CardContato = styled.div`
  .card {
    background: #232d41;
    border-radius: 20px;
    border: none;

    .bg-contato-card {
      background-image: url(${contato});
      background-size: cover;
      background-position: bottom center;
      background-repeat: no-repeat;
      border-radius: 20px 0 0 20px;

      @media screen and (max-width: 767px) {
        border-radius: 20px 20px 0 0;
      }
    }

    .border-logo-card {
      border-width: 2px !important;
    }

    h3 {
      font-weight: 600;
      color: #fff;
      margin-bottom: 30px;
    }

    p {
      color: #c5ccd6;
    }
  }
`;
