import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { isWebpSupported } from 'react-image-webp/dist/utils';
import {
  Banner,
  NossaEmpresa,
  SobreNossosProdutos,
  NosVamosAteVoce,
} from './styles';

const nossaEmpresa = isWebpSupported()
  ? require('~/assets/sobre-nos/nossa-empresa.webp')
  : require('~/assets/sobre-nos/nossa-empresa.png');
// const alexandre = isWebpSupported()
//   ? require('~/assets/sobre-nos/avatar.webp')
//   : require('~/assets/sobre-nos/avatar.png');
const sobreNossosProdutos = isWebpSupported()
  ? require('~/assets/sobre-nos/sobre-nossos-produtos.webp')
  : require('~/assets/sobre-nos/sobre-nossos-produtos.png');
const nosVamosAteVoce = isWebpSupported()
  ? require('~/assets/sobre-nos/nos-vamos-ate-voce.webp')
  : require('~/assets/sobre-nos/nos-vamos-ate-voce.png');

const SobreNos: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://www.fiariclean.com.br/sobre-nos" />
        <title>
          Venha conhecer mais sobre a gente e nossas técnicas de limpeza.
        </title>
        <meta
          name="description"
          content="Somos uma empresa especializada em limpeza de sofás, estofados, interiores de veiculo, poltronas estofadas de auditórios, limpeza e hidratação de estofados de couro, colchões, entre outros."
        />
      </Helmet>
      <Banner className="py-5">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1 className="display-4">Sobre Nós</h1>
              <h2 className="h5">
                A Fiari Clean é uma empresa especializada em limpeza de sofás,
                estofados, interiores de veiculo, poltronas estofadas de
                auditórios, limpeza e hidratação de estofados de couro,
                colchões, entre outros.
              </h2>
            </div>
          </div>
        </div>
      </Banner>
      <NossaEmpresa>
        <div className="container text-center text-md-left">
          <div className="row">
            <div className="order-1 order-md-0 col-md-6">
              <img src={nossaEmpresa} alt="Nossa Empresa" className="w-75" />
            </div>
            <div className="order-0 order-md-1 col-md-6 mb-3 mb-lg-0">
              <h2 className="h1">Nossa Empresa</h2>
              <p>
                A empresa possui infraestrutura para atender a demanda de
                comércios, residências e empresas.
              </p>
              <p>
                Além disso o serviço é realizado no conforto de sua casa ou
                empresa.
              </p>
              <p>
                Se você procura uma empresa que seja experiente em limpeza e
                conhecida pelo serviço de excelência, aqui é o seu lugar.
              </p>
              <p>
                Além disso o serviço é realizado no conforto de sua casa ou
                empresa.
              </p>
            </div>
          </div>
        </div>
      </NossaEmpresa>
      {/* <SobreMim className="my-5 d-none">
        <div className="container text-center text-md-left">
          <div className="row align-items-center">
            <div className="order-1 order-md-0 col-md-6">
              <h2 className="h1">Alexandre Fiari</h2>
              <p>
                Buscamos sempre trazer o melhor e com a máxima qualidade
                possível para nossos clientes.
              </p>
              <p>
                Buscamos sempre trazer o melhor e com a máxima qualidade
                possível para nossos clientes.
              </p>
            </div>
            <div className="order-0 order-md-1 col-md-6 text-center text-md-right mb-3 mb-md-0">
              <img src={alexandre} alt="Alexandre Fiari" className="w-75" />
            </div>
          </div>
        </div>
      </SobreMim> */}
      <SobreNossosProdutos className="py-5">
        <div className="container text-center text-md-left">
          <div className="row">
            <div className="order-1 order-md-1 col-md-6">
              <img
                src={sobreNossosProdutos}
                alt="Sobre Nossos Produtos"
                className="w-75"
              />
            </div>
            <div className="order-0 order-md-0 col-md-6 mb-3 mb-lg-0">
              <h2 className="h1">Sobre Nossos Produtos</h2>
              <p>
                Buscamos sempre trazer o melhor e com a máxima qualidade
                possível para nossos clientes.
              </p>
              <p>
                Produtos exclusivos e desenvolvidos por uma indústria química
                que está há mais 25 anos no mercado.
              </p>
              <p>
                Contudo, oferecendo um melhor resultado na eliminação de odores,
                além de equipamentos de última geração.
              </p>
            </div>
          </div>
        </div>
      </SobreNossosProdutos>
      <NosVamosAteVoce className="py-5">
        <div className="container text-center text-md-left">
          <div className="row">
            <div className="order-0 order-md-1 col-md-6 mb-3 mb-lg-0">
              <h2 className="h1">Nós vamos até você!</h2>
              <p>
                Devido a correria do dia-a-dia nós entendemos que está cada vez
                mais difícil a manutenção de um lar.
              </p>
              <p>A Fiari Clean oferece conforto e tranquilidade para você.</p>
              <p>
                Nós vamos até sua casa, na data marcada, com uma equipe treinada
                e uniformizada para garantir a sua segurança e de sua família.
              </p>
            </div>
            <div className="order-1 order-md-0 col-md-6">
              <img
                src={nosVamosAteVoce}
                alt="Sobre Nossos Produtos"
                className="w-100"
              />
            </div>
          </div>
        </div>
      </NosVamosAteVoce>
    </>
  );
};

export default SobreNos;
