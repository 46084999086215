import styled, { css } from 'styled-components';

interface MenuProps {
  opened: boolean;
}

export const Offset = styled.div``;

export const HeaderComponent = styled.header`
  padding: 15px 0;
  background: #fff;
  box-shadow: 0px 3px 6px #abcedb29;
`;

export const Content = styled.div`
  a {
    display: block;

    img {
      width: 100%;
    }
  }
`;

export const MenuButton = styled.button<MenuProps>`
  display: none;
  width: 40px;
  height: 40px;
  border: none;
  background: none;

  span {
    width: 40px;
    height: 5px;
    border-radius: 20px;
    background-color: #232d41;
    display: block;
    transition-duration: 0.3s;

    + span {
      margin-top: 5px;
    }

    ${(props) =>
      props.opened
        ? css`
            :nth-child(1) {
              transform: rotateZ(45deg);
              margin-top: 5px;
            }

            :nth-child(2) {
              display: none;
            }

            :nth-child(3) {
              transform: rotateZ(-45deg);
              margin-top: -5px;
            }
          `
        : ''}
  }

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const Menu = styled.ul<MenuProps>`
  list-style: none;
  margin: 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: relative;

  li {
    + li {
      margin-left: 50px;
    }

    a {
      color: #707070;
      transition-duration: 0.3s;
      font-weight: 600;

      :hover {
        text-decoration: none;
        color: #78aad5;
      }
    }

    a.active {
      color: #78aad5;
    }
  }

  @media screen and (max-width: 767px) {
    display: block;
    background: #fff;
    height: 100vh;
    position: absolute;
    border: 1px solid #232d41;
    right: ${(props) => (props.opened ? '0' : '-150%')};
    visibility: ${(props) => (props.opened ? 'visible' : 'hidden')};
    width: 150%;
    top: 61px;
    transition-duration: 0.2s;

    ::before {
      content: '';
      width: 200%;
      background: #00000088;
      height: 100vh;
      display: block;
      position: absolute;
      left: -200%;
      z-index: -1;
      opacity: 0;
      transition-duration: 0.5s;
      visibility: ${(props) => (props.opened ? 'visible' : 'hidden')};
    }

    ${(props) =>
      props.opened
        ? css`
            ::before {
              opacity: 1;
            }
          `
        : ''};

    li {
      margin: 15px 25px !important;
    }
  }
`;
