import React, { useEffect, useRef } from 'react';
import {
  FaFacebook,
  FaInstagram,
  FaWhatsapp,
  FaEnvelope,
  FaPhone,
  FaMapMarkerAlt,
  FaRegAddressCard,
} from 'react-icons/fa';
import { isWebpSupported } from 'react-image-webp/dist/utils';

import { FooterComponent } from './styles';
import GDPRPanel from '~/components/GDPRPanel';

const logo = isWebpSupported()
  ? require('~/assets/logotipos/logo-footer.webp')
  : require('~/assets/logotipos/logo-footer.png');

const Footer: React.FC = () => {
  const offsetRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (headerRef.current && offsetRef.current) {
      const { offsetHeight } = headerRef.current;
      offsetRef.current.style.marginBottom = `${offsetHeight}px`;
    }
  }, []);

  return (
    <>
      <FooterComponent>
        <div className="container py-4 text-center text-md-left">
          <div className="row">
            <div className="col-md-4 text-center">
              <img src={logo} alt="Logo" className="w-75" />
              <div className="d-flex justify-content-center mt-4">
                <a href="https://www.facebook.com/fiariclean/" target="blank">
                  <FaFacebook size={20} color="#fff" />
                </a>
                <a
                  href="https://www.instagram.com/fiariclean/"
                  target="blank"
                  className="mx-3"
                >
                  <FaInstagram size={20} color="#fff" />
                </a>
                <a
                  href="https://api.whatsapp.com/send?phone=5511975685553"
                  target="blank"
                >
                  <FaWhatsapp size={20} color="#fff" />
                </a>
              </div>
            </div>
            <div className="col-md-4 d-flex flex-column my-4 my-md-0">
              <h3 className="text-white">Contato</h3>
              <a
                href="mailto:contato@fiariclean.com.br"
                className="text-white mb-1 font-weight-thin d-flex align-items-center"
              >
                <FaEnvelope size={15} color="#fff" className="mr-2" />
                contato@fiariclean.com.br
              </a>
              <a
                href="tel:551142011655"
                className="text-white mb-1 font-weight-thin d-flex align-items-center"
              >
                <FaPhone size={15} color="#fff" className="mr-2" />
                (11) 4201-1655
              </a>
              <a
                href="https://api.whatsapp.com/send?phone=5511975685553"
                target="blank"
                className="text-white mb-1 font-weight-thin d-flex align-items-center"
              >
                <FaWhatsapp size={15} color="#fff" className="mr-2" />
                (11) 97568-5553
              </a>
            </div>
            <div className="col-md-4">
              <h3 className="text-white d-flex align-items-center">
                <FaMapMarkerAlt size={20} color="#fff" className="mr-2" />
                Endereço
              </h3>
              <p className="text-white font-weight-thin mb-1">
                R. Cesário Verde, 95
              </p>
              <p className="text-white font-weight-thin">
                Jardim Tupã - Barueri
              </p>
              <h3 className="text-white d-flex align-items-center h4">
                <FaRegAddressCard size={20} color="#fff" className="mr-2" />
                CNPJ
              </h3>
              <p className="text-white font-weight-thin">27.786.617/0001-30</p>
            </div>
          </div>
        </div>
      </FooterComponent>
      <GDPRPanel />
    </>
  );
};

export default Footer;
