import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { isWebpSupported } from 'react-image-webp/dist/utils';
import { Helmet } from 'react-helmet';

import {
  Banner,
  Welcome,
  SobreNossosRecursos,
  AlgunsDeNossoServicos,
} from './styles';

const nossosTrabalhos = isWebpSupported()
  ? require('~/assets/home/nossos-trabalhos.webp')
  : require('~/assets/home/nossos-trabalhos.png');
const warehouse = isWebpSupported()
  ? require('~/assets/icones/warehouse-icon.webp')
  : require('~/assets/icones/warehouse-icon.png');
const housekeeping = isWebpSupported()
  ? require('~/assets/icones/housekeeping-icon.webp')
  : require('~/assets/icones/housekeeping-icon.png');
const meeting = isWebpSupported()
  ? require('~/assets/icones/meeting-icon.webp')
  : require('~/assets/icones/meeting-icon.png');
const sofas = isWebpSupported()
  ? require('~/assets/shared/sofas.webp')
  : require('~/assets/shared/sofas.png');
const tapetes = isWebpSupported()
  ? require('~/assets/shared/tapetes.webp')
  : require('~/assets/shared/tapetes.png');
const colchoes = isWebpSupported()
  ? require('~/assets/shared/colchoes.webp')
  : require('~/assets/shared/colchoes.png');

const Home: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://www.fiariclean.com.br" />
        <title>Fiari Clean - Deixando a limpeza entrar em seu espaço</title>
        <meta
          name="description"
          content="Somos uma empresa especializada em limpeza de sofás, estofados, interiores de veiculo, poltronas estofadas de auditórios, limpeza e hidratação de estofados de couro, colchões, entre outros."
        />
      </Helmet>
      <Banner>
        <div className="container">
          <div className="row">
            <div className="col-md-6 bg-dark-blue">
              <h1 className="display-4">Fiari Clean</h1>
              <h2 className="h5">Deixando o seu espaço limpo e confortável</h2>
              <div className="d-flex flex-column flex-md-row justify-content-center mt-5 pt-5">
                <div className="col-12 col-md-5 my-2 my-md-0">
                  <Link
                    to={`${process.env.PUBLIC_URL}/sobre-nos`}
                    className="btn rounded-pill w-100 d-block btn-transparent"
                  >
                    Saiba mais
                  </Link>
                </div>
                <div className="col-12 col-md-5 my-2 my-md-0">
                  <Link
                    to={`${process.env.PUBLIC_URL}/servicos`}
                    className="btn rounded-pill w-100 d-block btn-blue"
                  >
                    Serviços
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Banner>
      <Welcome>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-7 col-lg-6 text-center py-5">
              <strong className="display-4 mb-3">BEM - VINDO</strong>
              <h2 className="h5">
                Oferecemos aos nossos clientes as melhores soluções de limpeza e
                higienização do mercado
              </h2>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6">
              <img
                src={nossosTrabalhos}
                alt="Nossos Trabalhos"
                className="w-100"
              />
            </div>
            <div className="col-md-6 text-center text-md-left mt-3 mt-md-0">
              <h3>Nosso trabalho</h3>
              <p>
                Somos Uma empresa que oferece qualidade e eficiência quando se
                trata de limpeza e segurança.
              </p>
              <p>Proporcionamos para nossos clientes serviços como:</p>
              <ul className="mb-4 mx-4 mx-md-0">
                <li>
                  <p>Comunicação instantânea</p>
                </li>
                <li>
                  <p>Eficiência e Qualidade</p>
                </li>
                <li>
                  <p>Limpeza rápida e eficaz</p>
                </li>
              </ul>
              <Link
                to={`${process.env.PUBLIC_URL}/servicos`}
                className="btn btn-light-blue"
              >
                Conferir mais serviços
              </Link>
            </div>
          </div>
        </div>
      </Welcome>
      <SobreNossosRecursos>
        <div className="container text-center text-md-left">
          <div className="row">
            <div className="col-12">
              <h2 className="display-4">Sobre nossos recursos</h2>
            </div>
            <div className="col-md-4 text-center">
              <img
                src={warehouse}
                alt="Serviços a Domicílio"
                className="w-25 mx-auto"
              />
              <h3 className="h5">Serviços a Domicílio</h3>
            </div>
            <div className="col-md-4 text-center">
              <img
                src={housekeeping}
                alt="Produtos Biodegradáveis"
                className="w-25 mx-auto"
              />
              <h3 className="h5">Produtos Biodegradáveis</h3>
            </div>
            <div className="col-md-4 text-center">
              <img
                src={meeting}
                alt="Satisfação e Confiança"
                className="w-25 mx-auto"
              />
              <h3 className="h5">Satisfação e Confiança</h3>
            </div>
          </div>
        </div>
      </SobreNossosRecursos>
      <AlgunsDeNossoServicos>
        <div className="container text-center text-md-left">
          <div className="row">
            <div className="col-12">
              <h2 className="display-4">Alguns de nossos serviços</h2>
            </div>
            <div className="col-md-4 text-center">
              <img
                src={sofas}
                alt="Serviços a Domicílio"
                className="w-100 mx-auto"
              />
              <h3 className="h5">Sofás</h3>
            </div>
            <div className="col-md-4 text-center">
              <img
                src={tapetes}
                alt="Produtos Biodegradáveis"
                className="w-100 mx-auto"
              />
              <h3 className="h5">Tapetes</h3>
            </div>
            <div className="col-md-4 text-center">
              <img
                src={colchoes}
                alt="Satisfação e Confiança"
                className="w-100 mx-auto"
              />
              <h3 className="h5">Colchões</h3>
            </div>
            <div className="col-12 text-center">
              <Link
                to={`${process.env.PUBLIC_URL}/servicos`}
                className="btn btn-light-blue py-2 px-3"
              >
                Ver mais serviços
              </Link>
            </div>
          </div>
        </div>
      </AlgunsDeNossoServicos>
    </>
  );
};

export default Home;
