import styled from 'styled-components';
import { isWebpSupported } from 'react-image-webp/dist/utils';

const banner = isWebpSupported()
  ? require('~/assets/banners/banner-home.webp')
  : require('~/assets/banners/banner-home.png');

export const Banner = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Piazzolla:wght@300;400&display=swap');

  background-image: url(${banner});
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;

  div.bg-dark-blue {
    margin: 100px 0;
    padding: 100px 0;
    background-color: #232d41;
    color: #fff;
    text-align: center;
    border-radius: 20px;

    h1 {
      font-weight: 600;
    }

    h2 {
      font-family: 'Piazzolla', serif;
      font-weight: 300;
    }

    a {
      padding: 10px 0;
    }
  }
`;

export const Welcome = styled.div`
  strong {
    font-weight: 600;
    color: #2a2a2a;
  }

  h2 {
    color: #c5ccd6;
  }

  h3 {
    font-weight: 600;
    color: #707070;
  }

  p {
    color: #c5ccd6;
  }

  ul {
    list-style: none;

    li {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      p {
        margin: 0;
      }

      ::before {
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #3a6689;
        display: block;
        margin-right: 10px;
      }
    }
  }
`;

export const SobreNossosRecursos = styled.div`
  padding: 3rem;

  h2 {
    color: #707070;
    font-weight: 600;
    margin-bottom: 3rem;
  }

  h3 {
    margin-top: 1rem;
    color: #707070;
  }

  @media screen and (max-width: 767px) {
    padding: 3rem 0rem;
  }
`;

export const AlgunsDeNossoServicos = styled.div`
  padding: 3rem;

  h2 {
    color: #707070;
    font-weight: 600;
    margin-bottom: 3rem;
  }

  h3 {
    margin: 1rem 0;
    color: #707070;
  }

  @media screen and (max-width: 767px) {
    padding: 3rem 0rem;
  }
`;
