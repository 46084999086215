import React, { useState, useEffect } from 'react';
import { isWebpSupported } from 'react-image-webp/dist/utils';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import {
  Banner,
  ButtonGroup,
  EstofadosEColchoes,
  Automoveis,
  Tratamentos,
  Outros,
} from './styles';

const sofas = isWebpSupported()
  ? require('~/assets/servicos/sofas.webp')
  : require('~/assets/servicos/sofas.png');
const colchoes = isWebpSupported()
  ? require('~/assets/servicos/colchoes.webp')
  : require('~/assets/servicos/colchoes.png');
const cadeiras = isWebpSupported()
  ? require('~/assets/servicos/cadeiras.webp')
  : require('~/assets/servicos/cadeiras.png');
const limpezaCompleta = isWebpSupported()
  ? require('~/assets/servicos/limpeza-completa.webp')
  : require('~/assets/servicos/limpeza-completa.png');
const limpezaEmDomicilio = isWebpSupported()
  ? require('~/assets/servicos/limpeza-em-domicilio.webp')
  : require('~/assets/servicos/limpeza-em-domicilio.png');
const hidratacaoDeCouro = isWebpSupported()
  ? require('~/assets/servicos/hidratacao-de-couro.webp')
  : require('~/assets/servicos/hidratacao-de-couro.png');
const tratamentoDeCouro = isWebpSupported()
  ? require('~/assets/servicos/tratamento-de-couro.webp')
  : require('~/assets/servicos/tratamento-de-couro.png');
const toldos = isWebpSupported()
  ? require('~/assets/servicos/toldos.webp')
  : require('~/assets/servicos/toldos.png');
const persianas = isWebpSupported()
  ? require('~/assets/servicos/persianas.webp')
  : require('~/assets/servicos/persianas.png');
const tapetes = isWebpSupported()
  ? require('~/assets/servicos/tapetes.webp')
  : require('~/assets/servicos/tapetes.png');

const Servicos: React.FC = () => {
  const [selected, setSelected] = useState('todos');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://www.fiariclean.com.br/servicos" />
        <title>
          Conheça um pouco sobre nossos serviços, especialidades e mais.
        </title>
        <meta
          name="description"
          content="Venha conhecer um pouco mais sobre os nossos serviços de limpeza de sofás, estofados, interiores de veiculo, limpeza e hidratação de couro e muito mais."
        />
      </Helmet>

      <Banner className="py-5">
        <div className="container py-5">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1 className="display-4">Nossos Serviços</h1>
              <h3 className="h5">
                Conheça um pouco sobre nossos serviços, especialidades e mais.
              </h3>
            </div>
          </div>
        </div>
      </Banner>
      <ButtonGroup className="pb-2 pb-md-5">
        <button
          type="button"
          className={`${selected === 'todos' ? 'active' : ''}`}
          onClick={() => setSelected('todos')}
        >
          Todos
        </button>
        <button
          type="button"
          className={`${selected === 'estofadosColchoes' ? 'active' : ''}`}
          onClick={() => setSelected('estofadosColchoes')}
        >
          Estofados & Colchões
        </button>
        <button
          type="button"
          className={`${selected === 'automoveis' ? 'active' : ''}`}
          onClick={() => setSelected('automoveis')}
        >
          Automóveis
        </button>
        <button
          type="button"
          className={`${selected === 'tratamentos' ? 'active' : ''}`}
          onClick={() => setSelected('tratamentos')}
        >
          Tratamentos
        </button>
        <button
          type="button"
          className={`${selected === 'outros' ? 'active' : ''}`}
          onClick={() => setSelected('outros')}
        >
          Outros
        </button>
      </ButtonGroup>
      <EstofadosEColchoes
        active={selected === 'todos' || selected === 'estofadosColchoes'}
        className="py-5"
      >
        <div className="container text-center text-md-left">
          <div className="row">
            <div className="col-12">
              <h2 className="h1">Estofados & Colchões</h2>
            </div>
            <div className="col-md-4 mb-3 mb-md-0">
              <button type="button" className="bg-white box">
                <div className="p-relative">
                  <img src={sofas} alt="Sofás" className="w-100" />
                  <div className="shadowHover">
                    <Link
                      to={`${process.env.PUBLIC_URL}/contato`}
                      className="btn btn-dark-blue"
                    >
                      Solicitar Orçamento
                    </Link>
                  </div>
                </div>
                <p className="text-center mb-0 p-4">Sofás</p>
              </button>
            </div>
            <div className="col-md-4 mb-3 mb-md-0">
              <button type="button" className="bg-white box">
                <div className="p-relative">
                  <img src={colchoes} alt="Colchões" className="w-100" />
                  <div className="shadowHover">
                    <Link
                      to={`${process.env.PUBLIC_URL}/contato`}
                      className="btn btn-dark-blue"
                    >
                      Solicitar Orçamento
                    </Link>
                  </div>
                </div>
                <p className="text-center mb-0 p-4">Colchões</p>
              </button>
            </div>
            <div className="col-md-4 mb-3 mb-md-0">
              <button type="button" className="bg-white box">
                <div className="p-relative">
                  <img src={cadeiras} alt="Cadeiras" className="w-100" />
                  <div className="shadowHover">
                    <Link
                      to={`${process.env.PUBLIC_URL}/contato`}
                      className="btn btn-dark-blue"
                    >
                      Solicitar Orçamento
                    </Link>
                  </div>
                </div>
                <p className="text-center mb-0 p-4">Cadeiras</p>
              </button>
            </div>
          </div>
        </div>
      </EstofadosEColchoes>
      <Automoveis
        active={selected === 'todos' || selected === 'automoveis'}
        className="py-5"
      >
        <div className="container text-center text-md-left">
          <div className="row align-items-center">
            <div className="col-12">
              <h2 className="h1">Automóveis</h2>
            </div>
            <div className="col-md-4 mb-3 mb-md-0">
              <button type="button" className="bg-white box">
                <div className="p-relative">
                  <img
                    src={limpezaCompleta}
                    alt="Limpeza completa"
                    className="w-100"
                  />
                  <div className="shadowHover">
                    <Link
                      to={`${process.env.PUBLIC_URL}/contato`}
                      className="btn btn-dark-blue"
                    >
                      Solicitar Orçamento
                    </Link>
                  </div>
                </div>
                <p className="text-center mb-0 p-4">Limpeza completa</p>
              </button>
            </div>
            <div className="col-md-4 mb-3 mb-md-0">
              <button type="button" className="bg-white box">
                <div className="p-relative">
                  <img
                    src={limpezaEmDomicilio}
                    alt="Limpeza em domicílio"
                    className="w-100"
                  />
                  <div className="shadowHover">
                    <Link
                      to={`${process.env.PUBLIC_URL}/contato`}
                      className="btn btn-dark-blue"
                    >
                      Solicitar Orçamento
                    </Link>
                  </div>
                </div>
                <p className="text-center mb-0 p-4">Limpeza em domicílio</p>
              </button>
            </div>
            <div className="col-md-4 mb-3 mb-md-0">
              <button type="button" className="bg-white box">
                <div className="p-relative">
                  <img
                    src={hidratacaoDeCouro}
                    alt="Hidratação de Couro"
                    className="w-100"
                  />
                  <div className="shadowHover">
                    <Link
                      to={`${process.env.PUBLIC_URL}/contato`}
                      className="btn btn-dark-blue"
                    >
                      Solicitar Orçamento
                    </Link>
                  </div>
                </div>
                <p className="text-center mb-0 p-4">Hidratação de Couro</p>
              </button>
            </div>
          </div>
        </div>
      </Automoveis>
      <Tratamentos
        active={selected === 'todos' || selected === 'tratamentos'}
        className="py-5"
      >
        <div className="container text-center text-md-left">
          <div className="row">
            <div className="col-12">
              <h2 className="h1">Tratamentos</h2>
            </div>
            <div className="col-md-4 mb-3 mb-md-0">
              <button type="button" className="bg-white box">
                <div className="p-relative">
                  <img
                    src={tratamentoDeCouro}
                    alt="Tratamento de couro"
                    className="w-100"
                  />
                  <div className="shadowHover">
                    <Link
                      to={`${process.env.PUBLIC_URL}/contato`}
                      className="btn btn-dark-blue"
                    >
                      Solicitar Orçamento
                    </Link>
                  </div>
                </div>
                <p className="text-center mb-0 p-4">Tratamento de couro</p>
              </button>
            </div>
          </div>
        </div>
      </Tratamentos>
      <Outros
        active={selected === 'todos' || selected === 'outros'}
        className="py-5"
      >
        <div className="container text-center text-md-left">
          <div className="row">
            <div className="col-12">
              <h2 className="h1">Outros</h2>
            </div>
            <div className="col-md-4 mb-3 mb-md-0">
              <button type="button" className="bg-white box">
                <div className="p-relative">
                  <img src={toldos} alt="Toldos" className="w-100" />
                  <div className="shadowHover">
                    <Link
                      to={`${process.env.PUBLIC_URL}/contato`}
                      className="btn btn-dark-blue"
                    >
                      Solicitar Orçamento
                    </Link>
                  </div>
                </div>
                <p className="text-center mb-0 p-4">Toldos</p>
              </button>
            </div>
            <div className="col-md-4 mb-3 mb-md-0">
              <button type="button" className="bg-white box">
                <div className="p-relative">
                  <img src={persianas} alt="Persianas" className="w-100" />
                  <div className="shadowHover">
                    <Link
                      to={`${process.env.PUBLIC_URL}/contato`}
                      className="btn btn-dark-blue"
                    >
                      Solicitar Orçamento
                    </Link>
                  </div>
                </div>
                <p className="text-center mb-0 p-4">Persianas</p>
              </button>
            </div>
            <div className="col-md-4 mb-3 mb-md-0">
              <button type="button" className="bg-white box">
                <div className="p-relative">
                  <img src={tapetes} alt="Tapetes" className="w-100" />
                  <div className="shadowHover">
                    <Link
                      to={`${process.env.PUBLIC_URL}/contato`}
                      className="btn btn-dark-blue"
                    >
                      Solicitar Orçamento
                    </Link>
                  </div>
                </div>
                <p className="text-center mb-0 p-4">Tapetes</p>
              </button>
            </div>
          </div>
        </div>
      </Outros>
    </>
  );
};

export default Servicos;
